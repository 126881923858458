import React from "react";
import { Helmet } from "react-helmet";
import {
  Contact,
  Header,
  Home,
  ParticlesContainer,
  Projects,
  ServiceCount,
  Skills,
} from "./";
import { AnimatePresence } from "framer-motion";
import { Socials } from "../utils/helper";
import { HomeSocialLinks } from "../components";

const App = () => {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Om Chothe - Filmmaker & Cinematographer</title>
        <meta name="description" content="Portfolio of Om Chothe, a passionate filmmaker and cinematographer specializing in aerial cinematography and storytelling." />
        <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
        {/* Add any other meta tags or links you need */}
      </Helmet>
      <div className="w-full xl:w-[1600px] py-32 px-4 lg:px-12 pr-4 lg:pr-32">
        <ParticlesContainer />
        <Header />
        <Home />
        <ServiceCount />
        <Skills />
        <Projects />  
        <Contact />
        <footer className="w-full flex flex-col items-center justify-start mt-32 mb-12">
          <p className="text-2xl md:text-3xl tracking-wide text-texlight">Om Chothe</p>
          <div className="flex items-center justify-center gap-8 md:gap-16 mt-8 md:mt-16">
            <AnimatePresence>
              {Socials &&
                Socials.map((item, index) => (
                  <HomeSocialLinks key={index} data={item} index={index} />
                ))}
            </AnimatePresence>
          </div>
        </footer>
      </div>
    </>
  );
};

export default App;
