import React from "react";
import { AnimatePresence, motion } from "framer-motion";
import { Hero } from "../assets";
import { HeroTypeWritter, HomeSocialLinks } from "../components";
import { Socials } from "../utils/helper";

const Home = () => {
  return (
    <section
      id="home"
      className="flex items-center justify-center flex-col gap-6 md:gap-12 relative"
    >
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 w-full">
        <div className="w-full h-full flex flex-col items-center lg:items-start justify-center gap-4">
          <div className="head text-center lg:text-left">
            <h2 className="text-2xl md:text-3xl lg:text-4xl text-texlight">
              Hello, It's me
              <span className="block tracking-wider text-3xl md:text-4xl lg:text-6xl mt-2 md:mt-4 text-white">
                Om Chothe
              </span>
            </h2>
            <h2 className="text-xl md:text-2xl lg:text-4xl text-texlight mt-2 md:mt-4">
              And I'm{" "}
              <HeroTypeWritter
                speed={100}
                words={[
                  "a freelancer...",
                  "a D.O.P...",
                  "a Director...",
                  "a Gamer...",
                  "a Aerial artist...",
                ]}
              />
            </h2>
          </div>
          <p className="text-sm md:text-base text-texlight mt-4 md:mt-6 text-center lg:text-left max-w-md lg:max-w-none">
            Hello, I'm Om, a filmmaker driven by a deep passion for storytelling
            and cinematography. My journey began with a fascination for
            capturing moments on camera, evolving into a dedication to crafting
            visually captivating narratives. Embracing the use of drones, I
            elevate my storytelling, offering unique perspectives and
            breathtaking aerial footage. Beyond technical expertise, I value the
            power of storytelling and connections, constantly seeking new
            experiences to enrich my craft. From short films to corporate
            videos, my work reflects my unwavering dedication to creating
            emotionally engaging and thought-provoking stories.
          </p>
          <div className="flex items-center justify-center gap-4 md:gap-8 lg:gap-16 mt-6 md:mt-8 lg:mt-16">
            <AnimatePresence>
              {Socials &&
                Socials.map((item, index) => (
                  <HomeSocialLinks key={index} data={item} index={index} />
                ))}
            </AnimatePresence>
          </div>
        </div>
        <div className="w-full h-full flex items-center justify-center lg:items-center mt-6 lg:mt-0">
          <motion.img
            initial={{ y: 0 }}
            animate={{ y: [-10, 10, -10] }}
            transition={{
              repeat: Infinity,
              duration: 4,
              ease: "linear",
            }}
            src={Hero}
            alt="Om Chothe"
            className="w-3/4 md:w-2/3 lg:w-auto h-auto object-contain"
          />
        </div>
      </div>
    </section>
  );
};

export default Home;
