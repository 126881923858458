import React from "react";
import { motion } from "framer-motion";

const SkillCard = ({ skill, percentage, color }) => {
  return (
    <div className="border border-[rgba(255,255,255,0.3)] rounded-lg px-4 py-3 w-full">
      <p className="text-sm md:text-base text-white mb-2">{skill}</p>
      <div className="w-full h-2 bg-[rgba(255,255,255,0.2)] rounded-full overflow-hidden">
        <motion.div
          initial={{ width: 0 }}
          animate={{ width: percentage }}
          transition={{ duration: 1.5 }}
          className="h-full rounded-full"
          style={{ background: color }}
        />
      </div>
      <p className="text-xs md:text-sm text-texlight mt-1 text-right">{percentage}</p>
    </div>
  );
};

export default SkillCard;
