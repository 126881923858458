import React from "react";
import { motion } from "framer-motion";
import { Leaf1, Leaf2 } from "../assets";
import { SkillCard } from "../components";

const skillsData = [
  { skill: "D.O.P.", percentage: "80%", color: "#4f42b5" },
  { skill: "Aerial Cinematography", percentage: "90%", color: "#e34c26" },
  { skill: "Editing", percentage: "75%", color: "#f5ff05" },
  { skill: "Photography", percentage: "80%", color: "#4B8BBE" },
];

const Skills = () => {
  return (
    <section
      id="skills"
      className="flex items-center justify-center flex-col gap-6 md:gap-12 my-6 md:my-12 px-4 md:px-8 lg:px-12"
    >
      <div className="w-full flex items-center justify-center py-12 md:py-24">
        <motion.div
          initial={{ opacity: 0, width: 0 }}
          animate={{ opacity: 1, width: 200 }}
          exit={{ opacity: 0, width: 0 }}
          transition={{ delay: 0.4 }}
          className="flex items-center justify-around w-52"
        >
          <img src={Leaf1} className="w-6 h-auto object-contain" alt="" />
          <p className="text-transparent bg-clip-text bg-gradient-to-r from-primary to-secondary">
            Skills
          </p>
          <img src={Leaf2} className="w-6 h-auto object-contain" alt="" />
        </motion.div>
      </div>
      <div className="w-full flex flex-col lg:flex-row gap-8 lg:gap-12">
        <div className="w-full lg:w-2/3 flex flex-col gap-4">
          <p className="text-transparent bg-clip-text bg-gradient-to-r from-primary to-secondary text-xl md:text-2xl text-center lg:text-left">
            My Skills and Work
          </p>
          <div className="text-sm md:text-base text-texlight tracking-wide space-y-4">
            <p className="text-justify">
              As a filmmaker deeply entrenched in the world of cinematography, my
              journey is fueled by an unwavering passion for storytelling. With
              each project, my goal is to establish a profound connection with
              audiences through visually captivating narratives that leave a
              lasting impact.
            </p>
            <p className="text-justify">
              My adventure in filmmaking began with a profound fascination for the
              art of cinematography. The ability to capture fleeting moments and
              breathe life into them through the delicate interplay of light,
              shadow, and composition has always intrigued me.
            </p>
            <p className="text-justify">
              One facet of filmmaking that I've embraced wholeheartedly is the use
              of drones to capture breathtaking aerial footage. By mastering the
              art of drone flying, I've been able to enhance my storytelling by
              offering unique perspectives and expansive vistas that lend depth
              and grandeur to my visuals.
            </p>
            <p className="text-justify">
              However, my journey as a filmmaker extends beyond technical
              proficiency; it's also about the power of storytelling and forging
              connections. I firmly believe that to create films that truly
              resonate, it's essential to constantly seek new experiences and
              perspectives.
            </p>
            <p className="text-justify">
              From crafting short films to producing corporate videos, and even
              delving into the automotive industry, each project has expanded my
              horizons and enriched my storytelling abilities.
            </p>
          </div>
        </div>
        <div className="w-full lg:w-1/3 flex flex-col gap-6">
          {skillsData.map((skill, index) => (
            <SkillCard
              key={index}
              skill={skill.skill}
              percentage={skill.percentage}
              color={skill.color}
            />
          ))}
        </div>
      </div>
    </section>
  );
};

export default Skills;
