import React from "react";

const ServiceCard = ({ count, text }) => {
  return (
    <div
      className="w-full sm:w-40 md:w-48 lg:w-52 h-28 sm:h-32 rounded-2xl sm:rounded-full border border-[rgba(255,255,255,0.3)] flex items-center justify-center flex-col gap-2 group hover:border-primary cursor-pointer relative bg-bgPrimary"
      style={{ boxShadow: "inset 0 0 10px rgba(255,255,255,0.3)" }}
    >
      <div className="hidden group-hover:block absolute -inset-1 blur-md bg-gradient-to-br from-primary to-secondary -z-10"></div>
      <p className="text-xl sm:text-2xl text-texlight group-hover:text-transparent group-hover:bg-clip-text group-hover:bg-gradient-to-r group-hover:from-primary group-hover:to-secondary capitalize font-sans tracking-widest">
        {count}
      </p>
      <p className="text-sm sm:text-base text-texlight group-hover:text-white text-center px-2">
        {text}
      </p>
    </div>
  );
};

export default ServiceCard;
