import {
  FaDiagramProject,
  FaEnvelope,
  FaInstagram,
  FaHouse,
  FaLinkedinIn,
  FaRenren,
  FaDiscord,
  FaXTwitter,
  FaWhatsapp,
} from "react-icons/fa6";
import { cam, drone, edits, Filmcraft} from "../assets";

export const Socials = [
  {
    id: `linkedin-${Date.now()}`,
    Icon: FaLinkedinIn,
    uri: "https://www.linkedin.com/in/om-chothe",
    color: "#0072b1",
  },
  {
    id: `instagram-${Date.now()}`,
    Icon: FaInstagram,
    uri: "https://www.instagram.com/_theskyartist/",
    color: "#F56040",
  },
  {
    id: `instagram-${Date.now()}`,
    Icon: FaInstagram,
    uri: "https://www.instagram.com/thedroneguy_1011/",
    color: "#F56040",
  },
  {
    id: `instagram-${Date.now()}`,
    Icon: FaWhatsapp,
    uri: "https://wa.me/917249225533",
    color: "green",
  },
  {
    id: `discord-${Date.now()}`,
    Icon: FaDiscord,
    uri: "https://discordapp.com/users/854944113156816927/",
    color: "##7289da",
  },
  {
    id: `x-${Date.now()}`,
    Icon: FaXTwitter,
    uri: "https://twitter.com/om_c_1011",
    color: "##FFFFFF",
  },
];

export const Menus = [
  {
    id: `home-${Date.now()}`,
    Icon: FaHouse,
    uri: "#home",
    name: "Home",
  },
  {
    id: `skills-${Date.now()}`,
    Icon: FaRenren,
    uri: "#skills",
    name: "Skills",
  },
  {
    id: `projects-${Date.now()}`,
    Icon: FaDiagramProject,
    uri: "#projects",
    name: "Projects",
  },
  {
    id: `contact-${Date.now()}`,
    Icon: FaEnvelope,
    uri: "#contact",
    name: "Contact",
  },
];

export const ProjectsData = [
  {
    id: `cam-${Date.now()}`,
    name: "Photography",
    imgSrc: cam,
    gitURL:
      "https://drive.google.com/drive/folders/1KguFl7g3Ye7kNgkH-D8Ec7fckJ_tp4Sq?usp=sharing",
  },
  {
    id: `cam-${Date.now()}`,
    name: "Drone ",
    imgSrc: drone,
    gitURL:
      "https://drive.google.com/drive/folders/1zvm-jL9e428NRA58fV3m05v3S1y4Togf?usp=sharing",
  },
  {
    id: `cam-${Date.now()}`,
    name: "Edits",
    imgSrc: edits,
    gitURL:
      "https://drive.google.com/drive/folders/1lhiXsTYZz5Ugb9J9L8TIych_eXdcYQJ4?usp=sharing",
  },
  {
    id: `cam-${Date.now()}`,
    name: "Filmcraft",
    imgSrc: Filmcraft,
    gitURL:
      "https://drive.google.com/drive/folders/1GRL4pDafYIZgpnr967Li3HoqbHPp2E57",
  },
];

export const isMobile = () => {
  return window.innerWidth <= 768;
};

export const isTablet = () => {
  return window.innerWidth > 768 && window.innerWidth <= 1024;
};

export const isDesktop = () => {
  return window.innerWidth > 1024;
};
