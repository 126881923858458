import React from "react";
import { ServiceCard } from "../components";

const ServiceCount = () => {
  return (
    <div className="w-full py-6 md:py-12 lg:py-24 mt-12 md:mt-24 flex items-center justify-center">
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4 md:gap-8 px-4 md:px-0">
        <ServiceCard count={"5"} text={"Commercial Projects"} />
        <ServiceCard count={"50+"} text={"Freelance Projects"} />
        <ServiceCard count={"2"} text={"Short Films"} />
        <ServiceCard count={"20+"} text={"Assisted Commercials"} />
      </div>
    </div>
  );
};

export default ServiceCount;
