import React, { useRef, useState } from "react";
import emailjs from "emailjs-com";
import { AnimatePresence, motion } from "framer-motion";
import { Leaf1, Leaf2 } from "../assets";
import { Alert } from "./";

const ContactUs = () => {
  const form = useRef();
  const [data, setData] = useState({
    firstName: "",
    email: "",
    phoneNumber: "",
    message: "",
  });
  const [alert, setAlert] = useState({
    isAlert: false,
    message: "",
    status: null,
  });

  const handleTextChange = (e) => {
    const { name, value } = e.target;
    setData((prevData) => ({ ...prevData, [name]: value }));
  };

  const validateInputs = () => {
    const { firstName, email, phoneNumber, message } = data;
    const emailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

    if (!firstName.trim()) {
      setAlert({
        isAlert: true,
        message: "First Name is required",
        status: "warning",
      });
      return false;
    }

    if (!email.trim() || !emailRegex.test(email)) {
      setAlert({
        isAlert: true,
        message: "Invalid email address",
        status: "warning",
      });
      return false;
    }

    if (!phoneNumber.trim()) {
      setAlert({
        isAlert: true,
        message: "Phone Number is required",
        status: "warning",
      });
      return false;
    }

    if (!message.trim()) {
      setAlert({
        isAlert: true,
        message: "Message is required",
        status: "warning",
      });
      return false;
    }

    return true;
  };

  const sendEmail = (e) => {
    e.preventDefault();

    if (validateInputs()) {
      emailjs
        .sendForm(
          "service_1y9fiyv",
          "template_3lj5htx",
          form.current,
          "hwOvKS6X1QzQtZoWI"
        )
        .then(() => {
          console.log("SUCCESS!");
          setData({
            firstName: "",
            email: "",
            phoneNumber: "",
            message: "",
          });
          setAlert({
            isAlert: true,
            message: "Thanks for contacting me",
            status: "success",
          });
          setTimeout(() => {
            setAlert({
              isAlert: false,
              message: "",
              status: null,
            });
          }, 4000);
        })
        .catch((error) => {
          console.log("FAILED...", error.text);
          setAlert({
            isAlert: true,
            message: `Error: ${error.text}`,
            status: "danger",
          });
          setTimeout(() => {
            setAlert({
              isAlert: false,
              message: "",
              status: null,
            });
          }, 4000);
        });
    }
  };

  return (
    <section
      id="contact"
      className="flex items-center justify-center flex-col gap-6 md:gap-12 my-6 md:my-12"
    >
      <AnimatePresence>
        {alert.isAlert && (
          <Alert status={alert.status} message={alert.message} />
        )}
      </AnimatePresence>
      <div className="w-full flex items-center justify-center py-12 md:py-24">
        <motion.div
          initial={{ opacity: 0, width: 0 }}
          animate={{ opacity: 1, width: 200 }}
          exit={{ opacity: 0, width: 0 }}
          transition={{ delay: 0.4 }}
          className="flex items-center justify-around w-52"
        >
          <img src={Leaf1} className="w-6 h-auto object-contain" alt="" />
          <p className="text-transparent bg-clip-text bg-gradient-to-r from-primary to-secondary">
            Contact Me
          </p>
          <img src={Leaf2} className="w-6 h-auto object-contain" alt="" />
        </motion.div>
      </div>
      <form ref={form} onSubmit={sendEmail} className="w-full max-w-lg px-4 md:px-0">
        <div className="w-full flex flex-col items-center justify-start gap-4">
          <input
            type="text"
            name="firstName"
            value={data.firstName}
            onChange={handleTextChange}
            placeholder="Full Name"
            className="w-full px-4 py-3 rounded-full border border-[rgba(255,255,255,0.3)] bg-transparent focus:border-primary outline-none text-white"
          />
          <input
            type="email"
            name="email"
            value={data.email}
            onChange={handleTextChange}
            placeholder="Email Address"
            className="w-full px-4 py-3 rounded-full border border-[rgba(255,255,255,0.3)] bg-transparent focus:border-primary outline-none text-white"
          />
          <input
            type="tel"
            name="phoneNumber"
            value={data.phoneNumber}
            onChange={handleTextChange}
            placeholder="Phone Number"
            className="w-full px-4 py-3 rounded-full border border-[rgba(255,255,255,0.3)] bg-transparent focus:border-primary outline-none text-white"
          />
          <textarea
            name="message"
            value={data.message}
            onChange={handleTextChange}
            placeholder="Message here..."
            className="w-full px-4 py-3 rounded-2xl border border-[rgba(255,255,255,0.3)] bg-transparent focus:border-primary outline-none text-white min-h-[120px]"
          ></textarea>
          <button
            type="submit"
            className="px-12 py-3 bg-gradient-to-br from-primary to-secondary rounded-full w-full hover:bg-gradient-to-br hover:from-black hover:to-black hover:border hover:border-primary hover:text-primary transition-all duration-300"
            onClick={sendEmail}
          >
            Send
          </button>
        </div>
      </form>
    </section>
  );
};

export default ContactUs;
